import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import useAuthActions from '../../../Auth/useAuthActions';
import { useAuth } from '../../../Auth/AuthContext';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { handleLogin, error } = useAuthActions();
  const { isAuthenticated } = useAuth();
  // const navigate = useNavigate();

  const handleClick = async (e) => {
    e.preventDefault();
    await handleLogin(username, password);
  };

  const [showPassword, setShowPassword] = useState(false);
  // console.log('error', error);

  if (isAuthenticated) {
    return <Navigate to="/dashboard/app" />;
  }

  // const handleClick = () => {
  //   navigate('/dashboard', { replace: true });
  // };

  return (
    <>
      <form onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField
            required
            name="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            label="Email address"
          />

          <TextField
            required
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>
      </Stack> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          style={{ marginTop: '20px' }}
          // onClick={handleClick}
        >
          Login
        </LoadingButton>
      </form>
    </>
  );
}
