import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import ProtectedRoute from './Auth/ProtectedRoute';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/Users/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/DashboardAppPage';
import HomePage from './pages/HomePage';
import Securities from './pages/Securities';
import CreateUser from './pages/Users/CreateUser';
import UserPermission from './pages/Users/UserPermission';
import TenantList from './pages/Tenants/TenantList';
import Licenses from './pages/Tenants/Lincenses';
import CreateTenants from './pages/Tenants/CreateTenant';
import Tabs from './pages/Tenants/Create Tenants/Tabs';
import Reports from './pages/Reports';
import Alerts from './pages/Alerts';
import Help from './pages/Help';
import Categories from './pages/Categories';
import VideosList from './pages/VideosList';
import VideoDetails from './pages/VideoDetails';
import UpdateVideo from './pages/UpdateVideo';
import AudioCategories from './pages/AudioCategories';
import AudioTags from './pages/AudioTags';
import AudioList from './pages/AudioList';
import AddAudio from './pages/AddAudio';
import AudioDetails from './pages/Audio/AudioDetails';
import UpdateAudio from './pages/Audio/UpdateAudio';
import TagsList from './pages/TagsList';
import AddBlog from './pages/Blogs/AddBlog';
import BlogList from './pages/Blogs/BlogsList';
import BlogDetails from './pages/Blogs/BlogDetails';
import EditBlog from './pages/Blogs/EditBlog';
import AddCard from './pages/CategoryCards/AddCard';
import UpdateCard from './pages/CategoryCards/UpdateCard';
import CardList from './pages/CategoryCards/CardList';
import CardDetails from './pages/CategoryCards/CardDetails';
import CustomerQuries from './pages/CustomerQuries/CustomerQuries';
import QueryDetails from './pages/CustomerQuries/QueryDetails';
import ContactList from './pages/ConactUs/ContactList';
import ContactDetails from './pages/ConactUs/ContactDetails';
import SearchByVideoTitle from './pages/Video/SearchByTitle';
import SearchByAudioTitle from './pages/Audio/SearchByTitle';
import AllTags from './pages/3DModel/AllTags';
import AddTag from './pages/3DModel/AddTag';
import ModelCategories from './pages/3DModel/Categories'
import AllFormats from './pages/3DModel/AllFormats';
import AddFormat from './pages/3DModel/AddFormat';
import AllModels from './pages/3DModel/AllModels';
import AddModel from './pages/3DModel/AddModel';
import ModelDetails from './pages/3DModel/ModelDetails';
import AllSkyBoxes from './pages/SkyBoxes/AllSkyBoxes';
import AddSkyBox from './pages/SkyBoxes/AddSkyBox';
import UpdateModel from './pages/3DModel/UpdateModel';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <ProtectedRoute element={<DashboardLayout />} />,
      children: [
        // { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'tenant_list', element: <TenantList /> },
        { path: 'licenses', element: <Licenses /> },
        { path: 'create_tenants', element: <Tabs /> },
        { path: 'user', element: <UserPage /> },
        { path: 'create_user', element: <CreateUser /> },
        { path: 'user_permission', element: <UserPermission /> },
        { path: 'blog', element: <BlogPage /> },
        { path: 'reports', element: <Reports /> },
        { path: 'videos', element: <Reports /> },
        { path: 'videos-list/:page', element: <VideosList /> },
        { path: 'videos-list/:paramstitle/:paramspage', element: <SearchByVideoTitle /> },
        { path: 'securities', element: <Securities /> },
        { path: 'alerts', element: <Alerts /> },
        { path: 'help', element: <Help /> },
        { path: 'category', element: <Categories /> },
        { path: 'video-details', element: <VideoDetails /> },
        { path: 'update-video', element: <UpdateVideo /> },
        { path: 'audio-category', element: <AudioCategories /> },
        { path: 'audio-tags', element: <TagsList /> },
        { path: 'add-audio-tags', element: <AudioTags /> },
        { path: 'audio-list/:page', element: <AudioList /> },
        { path: 'audio-list/:paramstitle/:paramspage', element: <SearchByAudioTitle /> },
        { path: 'add-audio', element: <AddAudio /> },
        { path: 'audio-details', element: <AudioDetails /> },
        { path: 'update-audio', element: <UpdateAudio /> },
        { path: 'add-blog', element: <AddBlog /> },
        { path: 'blog-list', element: <BlogList /> },
        { path: 'blog-details', element: <BlogDetails /> },
        { path: 'update-blog', element: <EditBlog /> },
        { path: 'add-card', element: <AddCard /> },
        { path: 'update-card', element: <UpdateCard /> },
        { path: 'card-list', element: <CardList /> },
        { path: 'card-details', element: <CardDetails /> },
        { path: 'customers-queries', element: <CustomerQuries /> },
        { path: 'query-details', element: <QueryDetails /> },
        { path: 'contact-list', element: <ContactList /> },
        { path: 'contact-details', element: <ContactDetails /> },
        { path: 'model-tags', element: <AllTags /> },
        { path: 'add-model-tag', element: <AddTag /> },
        { path: 'model-category', element: <ModelCategories /> },
        { path: 'model-formats', element: <AllFormats /> },
        { path: 'add-model-formats', element: <AddFormat /> },
        { path: 'model-list', element: <AllModels /> },
        { path: 'add-model', element: <AddModel /> },
        { path: 'model-details', element: <ModelDetails /> },
        { path: 'sky-box', element: <AllSkyBoxes /> },
        { path: 'add-skybox', element: <AddSkyBox /> },
        { path: 'update-model', element: <UpdateModel /> },
        // blog-details query-details
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
      children: [{ element: <Navigate to="/login" />, index: true }],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
