import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import Chart from 'react-apexcharts';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, CardContent, Card } from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

import { getVideos } from '../Services/videoServices';
import { getAudios } from '../Services/audioServices';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();

  const SemiGuageOptions = {
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        hollow: {
          margin: 0,
          size: '80%',
          background: '#fff',
          image: undefined,
        },
        track: {
          show: true,
          background: '#e0e0e0',
          strokeWidth: '97%',
          opacity: 1,
          margin: 0,
          color: 'red', // Set the track color to red
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 10,
            fontSize: '24px',
            // color: 'red', // Set the value color to red
          },
        },
      },
    },
    fill: {
      type: 'solid', // Use a solid fill color
      colors: ['#FE0000'], // Set the bar color to red
    },
    labels: ['Total tenants'],
  };

  const SemiGuagechartData = [67]; // Value to be shown on the gauge
  const SemiGuage1Options = {
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        hollow: {
          margin: 0,
          size: '80%',
          background: '#fff',
          image: undefined,
        },
        track: {
          show: true,
          background: '#e0e0e0',
          strokeWidth: '97%',
          opacity: 1,
          margin: 0,
          color: 'red', // Set the track color to red
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 10,
            fontSize: '24px',
            // color: 'red', // Set the value color to red
          },
        },
      },
    },
    fill: {
      type: 'solid', // Use a solid fill color
      colors: ['#F1C93B'], // Set the bar color to red
    },
    labels: ['Total tenants'],
  };

  const SemiGuage1chartData = [60];

  const SemiGuage2Options = {
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        hollow: {
          margin: 0,
          size: '80%',
          background: '#fff',
          image: undefined,
        },
        track: {
          show: true,
          background: '#e0e0e0',
          strokeWidth: '97%',
          opacity: 1,
          margin: 0,
          color: 'red', // Set the track color to red
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: 10,
            fontSize: '24px',
            // color: 'red', // Set the value color to red
          },
        },
      },
    },
    fill: {
      type: 'solid', // Use a solid fill color
      colors: ['#1A5D1A'], // Set the bar color to red
    },
    labels: ['Total tenants'],
  };

  const SemiGuage2chartData = [50];

  const chartOptions = {
    plotOptions: {
      radialBar: {
        hollow: {
          size: '70%', // Adjust inner circle size
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -10,
            show: true,
            color: '#888',
            fontSize: '14px',
          },
          value: {
            color: '#111',
            fontSize: '20px',
            show: true,
          },
        },
      },
    },
    labels: ['Gauge'],
  };

  const chart1Data = [65];
  const chart2Data = [50];

  const barChartOptions = {
    chart: {
      id: 'simple-bar',
    },
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
        horizontal: false,
        barHeight: '85%',
        barWidth: '10%',
      },
    },
    colors: [
      // this array contains different color code for each data
      'red',
      '#546E7A',
      '#d4526e',
      '#13d8aa',
      '#A5978B',
      '#2b908f',
      '#f9a3a4',
      '#90ee7e',
      '#f48024',
      '#69d2e7',
      '#13d8aa',
      '#A5978B',
      '#2b908f',
    ],

    xaxis: {
      title: {
        text: 'Features',
      },
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'August', 'August', 'August', 'August', 'May'],
    },
    yaxis: {
      title: {
        text: 'Demand Level Features',
      },
    },
  };

  const chartSeries = [
    {
      name: 'Data',
      data: [44, 55, 41, 67, 22, 43, 55, 88, 90, 50, 47],
    },
  ];

  const series = [44, 55];
  const DonutChartOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  // const barColors = ['#F44336', '#2196F3', '#FFC107', '#4CAF50', '#9C27B0', '#00BCD4'];
  const SplineChartOptions = {
    chart: {
      id: 'spline-chart',
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      title: {
        text: 'Money Income',
      },
    },

    stroke: {
      curve: 'smooth', // Use 'smooth' for spline effect
      width: 2, // Adjust the line thickness
    },
  };

  const SplineChartSeries = [
    {
      name: 'Series 1',
      data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 110, 98, 67],
    },
  ];
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalAudios, setTotalAudios] = useState(0);

  const fetchVideos = async (page = 1, limit = 1) => {
    try {
      const data = await getVideos(page, limit);
      setTotalVideos(data.totalVideos);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchAudios = async (page = 1, limit = 1) => {
    try {
      const data = await getAudios(page, limit);
      setTotalAudios(data.totalAudioRecords)
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAudios();
    fetchVideos();
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Analytics
        </Typography>

        <Grid container>
          <Grid container spacing={2} md={6}>
            <Grid item xs={6} sm={6} md={4}>
              <Card sx={{ maxWidth: 200, minHeight: 150 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Total Videos
                  </Typography>
                  <Typography variant="h5" component="div">
                    {totalVideos}
                  </Typography>
                </CardContent>
              </Card>
              {/* <AppWidgetSummary
                title="Total tenants"
                total={714000}
                // icon={'ant-design:android-filled'}
              /> */}
            </Grid>

            <Grid item xs={6} sm={6} md={4}>
              <Card sx={{ maxWidth: 200, minHeight: 150 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Total Audios
                  </Typography>
                  <Typography variant="h5" component="div">
                    {totalAudios}
                  </Typography>
                  {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    In this month
                  </Typography> */}
                </CardContent>
              </Card>
              {/* <AppWidgetSummary
                title="New tenants"
                total={1352831}
                color="info"
                // icon={'ant-design:apple-filled'}
              /> */}
            </Grid>
            <Grid item xs={6} sm={6} md={4}>
              <Card sx={{ maxWidth: 200, minHeight: 150 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    Totlal 3D Models
                  </Typography>
                  <Typography variant="h5" component="div">
                    100
                  </Typography>
                </CardContent>
              </Card>
              {/* <AppWidgetSummary
                title="Total tickets"
                total={1723315}
                color="warning"
                icon={'ant-design:windows-filled'}
              /> */}
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total tickets generated"
                total={1723315}
                color="warning"
                //  icon={'ant-design:windows-filled'}
              />
            </Grid> */}
            <Grid sx={{ backgroundColor: 'white', borderRadius: 5, marginTop: 2 }} item xs={12} sm={6} md={4}>
              8
              <Chart options={SemiGuageOptions} series={SemiGuagechartData} type="radialBar" height="180" />
              Total tenant on trial
            </Grid>
            <Grid sx={{ backgroundColor: 'white', borderRadius: 5, marginTop: 2 }} item xs={12} sm={6} md={4}>
              7
              <Chart options={SemiGuage1Options} series={SemiGuage1chartData} type="radialBar" height="180" />
              Total tenant on yearly subscription
            </Grid>
            <Grid sx={{ backgroundColor: 'white', borderRadius: 5, marginTop: 2 }} item xs={12} sm={6} md={4}>
              7
              <Chart options={SemiGuage2Options} series={SemiGuage2chartData} type="radialBar" height="180" />
              Total tenant on yearly subscription
            </Grid>
            {/* <Grid sx={{ backgroundColor: 'white', borderRadius: 5, marginTop: 2 }} item xs={12} sm={6} md={6}>
              7
              <Chart options={DonutChartOptions} series={series} type="donut" height="300" />
              Total tenant on yearly subscription
            </Grid> */}
            {/* <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total tickets generated"
                total={1723315}
                color="warning"
                //  icon={'ant-design:windows-filled'}
              />
            </Grid> */}

            {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

            {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid> */}
          </Grid>
          <Grid item sx={{ paddingLeft: { md: 5, lg: 5 } }} xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title="Space usage"
              chartData={[
                { label: 'Space available', value: 5435 },
                { label: 'Space used', value: 4344 },
                // { label: 'Europe', value: 1443 },
                // { label: 'Africa', value: 4443 },
              ]}
              total={170000}
              chartColors={[
                '#5D9C59',
                '#FFB84C',
                // theme.palette.info.main,

                // theme.palette.error.main,
              ]}
            />
          </Grid>
          <Grid item sx={{ paddingLeft: { md: 5, lg: 5 } }} xs={12} md={6} lg={3}>
            <AppCurrentVisits
              title="System Utilization"
              chartData={[
                { label: 'Space available', value: 7000 },
                { label: 'Space used', value: 6000 },
                // { label: 'Europe', value: 1443 },
                // { label: 'Africa', value: 4443 },
              ]}
              total={17000}
              chartColors={[
                '#5D9C59',
                '#FFB84C',
                // theme.palette.info.main,

                // theme.palette.error.main,
              ]}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total tickets generated" total={1723315} color="warning"
            //  icon={'ant-design:windows-filled'} 
             />
          </Grid> */}
          <Grid sx={{ marginTop: 5 }} item xs={12} md={12} lg={12}>
            <Card sx={{ padding: '30px' }}>
              Statistics
              <Chart options={SplineChartOptions} series={SplineChartSeries} type="line" height="300" />
            </Card>
          </Grid>

          {/* <Grid sx={{ marginTop: 5 }} item xs={12} md={12} lg={6}>
           <Chart options={SplineChartOptions} series={SplineChartSeries} type="line" height="300" />
           </Grid> */}

          <Grid item xs={12} md={6} lg={12}>
            <AppConversionRates
              title="Tanenet licence taker"
              subheader="States"
              chartData={[
                { label: 'ABC company', value: 400 },
                { label: 'Company', value: 430 },
                { label: 'zru group', value: 448 },
                { label: 'Fatima group', value: 470 },
                { label: 'Prismatic', value: 540 },
                { label: 'HSE main campus', value: 580 },
                { label: 'Programmer force', value: 690 },
                { label: 'Arbi soft', value: 1100 },
                { label: 'Beyond tech.', value: 1200 },
                { label: 'Hazel soft', value: 1380 },
                { label: 'Beyond tech.', value: 1200 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={12}>
            <h2>Features Demand</h2>
            <Card sx={{ padding: '30px' }}>
              <Chart options={barChartOptions} series={chartSeries} type="bar" height="450" width="100%" />
            </Card>
          </Grid>

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid> */}

          {/* <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
}
